import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { FormsModule } from '@angular/forms';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PageRegisterComponent } from '../../../amm-pages/page-register/page-register.component';
import { MATERIAL_ICON_NAME } from '../../constants';
import { ProductService, StorageService } from '../../services';
import { AmmDialogService } from '../../services/amm-dialog-service.service';
import { AuthStore, ShoppingInfoStore } from '../../stores';
import { AmmButtonWithBageComponent } from '../amm-button-with-bage/amm-button-with-bage.component';
import { stringify } from 'querystring';
import { HasStringValue } from '../../../components.global';
@Component({
  selector: 'amm-global-nav-bar',
  standalone: true,
  imports: [
    RouterModule,
    AmmButtonWithBageComponent,
    OverlayPanelModule,
    DividerModule,
    ButtonModule,
    DynamicDialogModule,
    PageRegisterComponent,
    FormsModule,
  ],
  templateUrl: './amm-global-nav-bar.component.html',
  styleUrl: './amm-global-nav-bar.component.scss',
  providers: [DialogService],
})
export class AmmGlobalNavBarComponent implements OnInit {
  @Input()
  showFilterButton?: boolean;
  @Input()
  showFilterPanel?: boolean;
  @Output()
  searchKey = new EventEmitter<string>();
  @Output()
  showFilter = new EventEmitter<boolean>();
  public iconName = MATERIAL_ICON_NAME;
  private _router: Router = inject(Router);
  public storageService: StorageService = inject(StorageService);
  public shopInfoStore = inject(ShoppingInfoStore);
  private dialogService = inject(DialogService);
  private ammDialogService = inject(AmmDialogService);
  public  productService: ProductService = inject(ProductService);
  ref: DynamicDialogRef | undefined;
  public authStore = inject(AuthStore);


  ngOnInit(): void {
    this.searchHistory=this.storageService.getLocalItem('searchHistory');
    // this.searchHistory = JSON.parse(localStorage.getItem('searchHistory') || '') || [];
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
  onButtonClick(event: string) {
    switch (event) {
      case MATERIAL_ICON_NAME.favorite:
        this._router.navigate(['/favorites']);
        break;
      case MATERIAL_ICON_NAME.shoppingCart:
        this._router.navigate(['/shopping-carts']);
        break;
      case MATERIAL_ICON_NAME.orders:
        //TODO: favorite page routing hasn't been
        // designed yet. so it just redirect to home

        this._router.navigate(['/orders']);
        break;
    }
  }

  onLogin() {
    this.ammDialogService.loginDialogRef = this.dialogService.open(
      PageRegisterComponent,
      {
        showHeader: false,
        // closable:true,
        width: '760px',
        style: {
          padding: '0px',
          'border-radius': '24px',
          width: '760px',
          'min-height': '532px',
        },
        contentStyle: {
          overflow: 'visible',
          padding: '0px',
          'border-radius': '24px',
          width: '760px',
          'min-height': '532px',
        },
        breakpoints: {
          '760px': '95vw',
        },
      },
    );
  }
  onExplore() {
    this._router.navigate(['/']);
  }

  // showFilterPanel:boolean=false;
  getFilterButtonLable(): string {
    return this.showFilterPanel?'Hide filter & sort':'Show filter & sort';
  }
  showHideFilter():void{
    this.showFilterPanel=!this.showFilterPanel;
    this.showFilter.emit(this.showFilterPanel);
  }
  searchValue: string = '';
  setSearchValue(): void {
    if (this.searchValue) {
      this.showFilterButton = true;
    } else {
      this.showFilterButton = false;
    }
    this.showSearchHistory = false;
    this.showAutoComplete = false;
    if (HasStringValue(this.searchValue)) {
      this.searchHistory.push(this.searchValue);
      if (this.searchHistory.length > 8) {
        this.searchHistory.splice(0, 1);
      }
      this.storageService.setLocalItem('searchHistory',this.searchHistory);
      // localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
    }
    this.searchKey.emit(this.searchValue);
  }
  hasFilterButton(): boolean {
    return this.showFilterButton || false;
  }
  showSearchHistory: boolean = false;
  showAutoComplete: boolean = false;
  autoComplete?:Array<string>;
  setAutoCompleteValue(): void {
    this.showAutoComplete = true;
    this.showSearchHistory = false;
    if (this.searchValue.length>1){
      this. productService.getAutoComplete(this.searchValue).subscribe(autoC=>{
        this.autoComplete=autoC.splice(8);
      })
    }
    
  }
  showSearchHistoryItems(): void {
      this.showSearchHistory = true;
  }
  searchHistory: Array<string> = [];
  delOneSearchHistory(i: number): void {
    this.searchHistory.splice(i, 1);
  }
  noSearch(): void {
    setTimeout(() => {
      this.showSearchHistory = false;
      this.showAutoComplete = false;
    }, 500);
    // this.searchValue='';
  }
  deleteSearchValue(): void {
    this.searchValue = '';
    this.showFilterButton=false;
  }
  setSearchValueFromHistory(item:string):void{
    this.searchValue=item;
    this.showFilterButton = true;
    this.showSearchHistory = false;
    this.showAutoComplete = false;
    this.searchKey.emit(item);
  }
}
