import { CommonModule } from '@angular/common';
import { Component, input, InputSignal, output } from '@angular/core';

@Component({
  selector: 'amm-button-with-bage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './amm-button-with-bage.component.html',
  styleUrl: './amm-button-with-bage.component.scss',
})
export class AmmButtonWithBageComponent {
  public iconName: InputSignal<string> = input('search', {
    alias: 'iconName',
  });

  public counter: InputSignal<number> = input(0, {
    alias: 'counter',
  });

  public iconDesciption: InputSignal<string> = input('', {
    alias: 'iconDesciption',
  });
  public onButtonClick = output<string>({ alias: 'onButtonClick' });
  public onBtnClick() {
    this.onButtonClick.emit(this.iconName());
  }
}
