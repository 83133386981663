import { Component, HostListener, inject, OnDestroy } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ShoppingInfoStore } from './amm-share/stores';

@Component({
  selector: 'amm-root',
  standalone: true,
  imports: [RouterLink, RouterOutlet],

  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy {
  title = 'Amm-Buyer';

  private shoppingCartStore = inject(ShoppingInfoStore)
  constructor() {}

  ngOnDestroy(): void {
      this.shoppingCartStore.saveShoppingCartsToDB();
  }
  @HostListener('window:storage')
  onLocalStorageChange() {}
}
