import { CommonModule } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AutoFocusModule } from 'primeng/autofocus';
import { DialogService } from 'primeng/dynamicdialog';
import { forceLogin } from '../../decorators';
import { IntersectionObserverDirective } from '../../directives';
import { Product } from '../../models/catalog.model';
import { AmmDialogService, StorageService } from '../../services';
import { GlobalNavStore, ShoppingInfoStore } from '../../stores';
import { resizeAllGridItems } from '../../utilitys';
import { AmmQtyAdjusterComponent } from '../amm-qty-adjuster/amm-qty-adjuster.component';
import { ProductQuickViewComponent } from './../../../amm-pages/product-quick-view/product-quick-view.component';

@Component({
  selector: 'amm-item-card',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AutoFocusModule,
    AmmQtyAdjusterComponent,
    ProductQuickViewComponent,
    IntersectionObserverDirective,
  ],
  templateUrl: './item-card.component.html',
  styleUrl: './item-card.component.scss',
  providers: [DialogService],
})
export class ItemCardComponent {
  public showAdjuster: boolean = false;
  @Input() product!: Product;
  public mainImg!: string;

  public shopInfoStore = inject(ShoppingInfoStore);
  public globalNavIndicatorStore = inject(GlobalNavStore);
  public currentQty = computed(
    () =>
      this.shopInfoStore
        .shoppingCarts()
        .find((x) => x.id === this.product.id || x.code === this.product.code)
        ?.qty || 0,
  );

  private currentQty$ = toObservable(this.currentQty);
  public storageService = inject(StorageService);
  private dialogService = inject(DialogService);
  private messageService = inject(MessageService);
  private ammDialogService = inject(AmmDialogService);

  private router: Router = inject(Router);

  ngOnInit() {
    this.mainImg = this.product.mainImage?.uri || '';
    this.currentQty$.subscribe((v) => {
      if (v == 0) {
        this.showAdjuster = false;
      }
    });
  }

  onClick(event: Event, id: number) {
    this.router.navigate(['/details', id]);
  }
  getFavoriteClass() {
    if (this.product.favorite) {
      return 'quick-action-favorite-selected';
    } else {
      return 'quick-action-favorite';
    }
  }

  onFavorite() {
    this.addFavorite(this.storageService, this.ammDialogService, this.dialogService);
  }
  @forceLogin('buyerInfo')
  addFavorite(storageService: StorageService, ammDialogService: AmmDialogService, dialogService: DialogService){
    this.product.favorite = !this.product.favorite;
    if (this.product.favorite) {
      this.shopInfoStore.increaseFavorite(this.product.id);
      this.messageService.add({
        severity: 'contrast',
        detail: 'Added to favorite',
        key: 'product-operation-toast',
      });
    } else {
      this.shopInfoStore.decreaseFavorite(this.product.id);
      this.messageService.add({
        severity: 'contrast',
        detail: 'Removed from favorite',
        key: 'product-operation-toast',
      });
    }
  }


  onShopCartClick() {
    if (this.product.isMainVariant) {
      // Open quick shop action
      this.dialogService.open(ProductQuickViewComponent, {
        showHeader: true,
        data: {
          product: this.product,
        },
        closable: true,
        style: {
          'max-width': '960px',
          'max-height': '640px',
        },
      });
    } else {
      this.showAdjuster = true;
    }
  }
  onQtyDecrease(qty: number) {
    this.shopInfoStore.decreaseShopingCart(this.product.id);
    this.messageService.add({
      severity: 'contrast',
      detail: 'Removed from ShopCart',
      key: 'product-operation-toast',
    });
  }
  onIncrease(qty: number) {
    this.shopInfoStore.increaseShopingCart(
      this.product.id,
      this.product.code || '',
    );
    this.messageService.add({
      severity: 'contrast',
      detail: 'Added to ShopCart',
      key: 'product-operation-toast',
    });
  }
  onInterSecting(isIntersecting: boolean) {
    // console.log(isIntersecting);
    // if (!isIntersecting) {
    //   this.globalNavIndicatorStore.enableNavIndicator();
    // } else {
    //   this.globalNavIndicatorStore.disableNavIndicator();
    // }
  }
  onImgLoad($event: any) {
    resizeAllGridItems('item', 'mansory', '.content', document, window);
  }
}
