<button
  class="flex justify-center items-center icon-button"
  style="position: relative; padding: 16px !important; width: fit-content"
  (click)="onBtnClick()"
>
  <span class="material-symbols-outlined icon-img">{{ iconName() }}</span>
  <span class="icon-desciption">{{ iconDesciption() | titlecase }}</span>
  @if (counter() > 0) {
    <div class="badge-container">{{ counter() }}</div>
  }
</button>
