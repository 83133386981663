<div class="flex flex-col amm-foot gap-3">
  <div><img class="foot-logo" src="../../../../assets/img/logo/logo_complete_horizontal.png"
      alt="Opps! logo is missing"></div>
  <div class="flex flex-row justify-between">
    <small class="font-medium copy-right">© 2024 AmMall Inc. All rights reserved.</small>
    <small class="flex flex-row gap-8">
      <div class="font-semibold"><a [routerLink]="['/help-center']">Help Center</a></div>
      <div class="font-semibold"><a [routerLink]="['/legal-docs', 'PRIVACY']">Privacy Policy</a></div>
      <div class="font-semibold"><a [routerLink]="['/legal-docs', 'TERMS']">Terms & Conditions</a></div>
      <div class="font-semibold"><a [routerLink]="['/legal-docs', 'ADS_POLICY']">Interest-Based Ads Policy</a></div>
    </small>
  </div>
</div>
