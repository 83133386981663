import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  inject,
  OnInit,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { debounceTime, Subject, take } from 'rxjs';
import { AmmFooterComponent } from '../../amm-share/components/amm-footer/amm-footer.component';
import { AmmGlobalNavBarComponent } from '../../amm-share/components/amm-global-nav-bar/amm-global-nav-bar.component';
import { ItemCardComponent } from '../../amm-share/components/item-card/item-cardcomponent';
import { ProductCardSkeletonLoaderComponent } from '../../amm-share/components/product-card-skeleton-loader/product-card-skeleton-loader.component';
import { ViewMoreComponent } from '../../amm-share/components/view-more/view-more.component';
import { mockProducts } from '../../amm-share/mockData';
import { Product } from '../../amm-share/models/catalog.model';
import { ProductModel } from '../../amm-share/models/ProductModel';
import { MansoryService } from '../../amm-share/services';
import { ProductService } from '../../amm-share/services/productService';
import {
  GlobalNavStore,
  ProductStore,
  ShoppingInfoStore,
} from '../../amm-share/stores';
import { resizeAllGridItems } from '../../amm-share/utilitys';
import { SidebarModule } from 'primeng/sidebar';
import { AmmSearchFilterComponent } from '../../amm-share/components/amm-search-filter/amm-search-filter.component';
const MAX_PRODUCT_LOAD_LIMIT: number = 32;
const MAX_PRODUCT_LOADING_LIMIT: number = 16; // default is 16

@Component({
  selector: 'amm-home',
  standalone: true,
  imports: [
    CommonModule,
    ItemCardComponent,
    RouterLink,
    RouterOutlet,
    AmmFooterComponent,
    AmmGlobalNavBarComponent,
    ToastModule,
    SidebarModule,
    ProductCardSkeletonLoaderComponent,
    ViewMoreComponent,
    AmmSearchFilterComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements AfterViewInit, OnInit {
  productList: Product[] = [];
  productService: ProductService = inject(ProductService);
  filteredProductList: Product[] = [];

  public globalNavIndicatorStore = inject(GlobalNavStore);
  public productStore = inject(ProductStore);
  productPlaceHolders: ProductModel[] = mockProducts.filter(
    (p, idx) => idx <= MAX_PRODUCT_LOADING_LIMIT,
  );
  public showSkeletonLoader: boolean = false;
  public shoppingCartInfoStore = inject(ShoppingInfoStore);

  private mansoryService = inject(MansoryService);
  private destory$: Subject<boolean> = new Subject<boolean>();
  private currentPageIndex = 0;
  ngOnInit(): void {
    this.productStore.loadProducts(0, MAX_PRODUCT_LOAD_LIMIT);
    this.shoppingCartInfoStore.loadShoppingCarts();

    this.mansoryService.onPageLoad$
      .pipe(debounceTime(300), take(1))
      .subscribe((event) => {
        resizeAllGridItems('item', 'mansory', '.content', document, window);
      });
    this.mansoryService.onPageResize$
      .pipe(debounceTime(300), take(1))
      .subscribe((event) => {
        resizeAllGridItems('item', 'mansory', '.content', document, window);
      });
  }
  ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (!entry.isIntersecting) {
          this.globalNavIndicatorStore.enableNavIndicator();
        } else {
          this.globalNavIndicatorStore.disableNavIndicator();
        }
      });
      observer.observe(document.querySelector('.intersect-marker')!);
    }
  }
  @HostListener('window:load', ['$event'])
  onPageLoad(event: Event) {
    this.mansoryService.onPageLoad$.next(event);
  }

  @HostListener('window:resize', ['$event'])
  onPageResize(event: Event) {
    this.mansoryService.onPageResize$.next(event);
  }

  filterResults(text: string) {
    //TODO： This need to be hooked up with the Search UI
  }

  onNavBarIndicatorClick() {
    this.globalNavIndicatorStore.disableNavIndicator();
  }
  onViewMoreClick() {
    this.productStore.setIsLoading();
    this.productStore.viewMoreProducts(this.currentPageIndex++);
    this.productPlaceHolders = mockProducts.filter(
      (p, idx) => idx < MAX_PRODUCT_LOADING_LIMIT,
    );
  }
  onLoadAgain() {
    // re-call the API to fetch the products
    this.productStore.setIsLoading();
    this.productStore.viewMoreProducts(
      this.currentPageIndex,
      MAX_PRODUCT_LOAD_LIMIT,
    );
    this.productPlaceHolders = mockProducts.filter(
      (p, idx) => idx < MAX_PRODUCT_LOADING_LIMIT,
    );
  }
  sidebarVisible:boolean=false;
  showFilterSideBar(show:boolean):void{
    this.sidebarVisible=show;
  }
}
