import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProductModel } from '../../amm-share/models/ProductModel';
import { ProductService } from '../../amm-share/services/productService';

import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, FormsModule],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss',
})
export class DetailsComponent {
  @Input() product_id: number = 0;
  route: ActivatedRoute = inject(ActivatedRoute);
  productService = inject(ProductService);
  productModel: ProductModel | undefined;

  ngOnInit() {
    this.product_id = parseInt(this.route.snapshot.params['id']);

    // this.productService.getProductById(this.product_id).then(p => {
    //     this.productModel = p

    //     if (this.productModel) {
    //         this.productModel.cover_medias = [this.productModel?.cover_medias[0]]
    //     }
    // });
  }
  constructor() {}
}
