<amm-global-nav-with-indicator
  [showNavIndicator]="navIndicatorService.getNavIndicator()"
  (onNavIndicatorClick)="navIndicatorService.disableNavIndicator()"
></amm-global-nav-with-indicator>

<router-outlet name="mainBody"> </router-outlet>

<div class="pt-12 pr-16 pl-16 pb-8"><amm-footer></amm-footer></div>
<p-toast
  position="bottom-center"
  key="product-operation-toast"
  [style]="{'width':'fit-content'}"
>
  <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
    <div class="flex flex-row gap-2 product-operation-toast-wrapper items-center">
      <span class="material-symbols-outlined"> check </span>
      <div class="font-medium line-height-3 text-base toast-text">{{
        message.detail
      }}</div>
    </div>
  </ng-template>
</p-toast>
