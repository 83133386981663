import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { of, switchMap } from 'rxjs';
import { PageRegisterComponent } from '../../amm-pages/page-register/page-register.component';
import { AuthService } from '../services';

export const forceLoginGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const dialogService = inject(DialogService);
  if (authService.isLoggedIn()) return of(true);

  const ref = dialogService.open(PageRegisterComponent, {
    showHeader: false,
    width: '760px',
    style: {
      padding: '0px',
      'border-radius': '24px',
      width: '760px',
      'min-height': '532px',
    },
    contentStyle: {
      overflow: 'visible',
      padding: '0px',
      'border-radius': '24px',
      width: '760px',
      'min-height': '532px',
    },
    breakpoints: {
      '760px': '95vw',
    },
  });

  return ref.onClose.pipe(switchMap((x) => of(authService.isLoggedIn())));
};
