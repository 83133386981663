<article>
  <img class="listing-photo"  [src]="productModel?.cover_medias" alt="Exterior photo of {{ productModel?.product_name }}"
  />
  <section class="listing-description">
    <h2 class="listing-heading">{{ productModel?.product_name }}</h2>
    <h2 class="listing-heading">{{ productModel?.store_name }}</h2>
  </section>

  <section class="listing-features">
    <ul>
      <li>Price: {{ productModel?.price }}</li>
    </ul>
  </section>
</article>
