<div class="header-container">
  @if (globalNavIndicatorStore.showNavIndicator()) {
    <div
      class="ml-auto mr-auto nav-bar-indicator-wrapper cursor-pointer"
      (click)="onNavBarIndicatorClick()"
    >
      <div class="flex gap-2 nav-bar-indicator">
        <span class="material-symbols-outlined text-3xl"> arrow_upward </span>
        <span>Show navigation</span>
      </div>
    </div>
  } @else {
    <div class="ml-auto mr-auto w-full pt-4 pr-8 pl-8 pb-4 nav-wrapper">
      <amm-global-nav-bar (showFilter)="showFilterSideBar($event)" (showFilterPanel)="sidebarVisible"></amm-global-nav-bar>
    </div>
  }
</div>
@if (sidebarVisible) {
<amm-search-filter class="side-bar" (showFilter)="showFilterSideBar($event)"
></amm-search-filter>
}
<!-- <p-sidebar [style]="{width:'400px'}" [(visible)]="sidebarVisible">
<amm-search-filter></amm-search-filter>
</p-sidebar> -->
<div class="intersect-marker"></div>
<div class="product-container m-auto h-full">
  <div class="mansory">
    @for (filteredProduct of productStore.products(); track $index) {
      <amm-item-card class="item" [product]="filteredProduct"></amm-item-card>
    }
    @if (productStore.isLoading()) {
      @for (product of productPlaceHolders; track $index) {
        <amm-product-card-skeleton-loader
          class="item"
        ></amm-product-card-skeleton-loader>
      }
    }
  </div>
  @if (productStore.hasReachBotom()) {
    <div class="flex flex-row justify-center">
      <span>Opps! you reached the bottom. No more products to display</span>
    </div>
  } @else {
    <amm-view-more
      [isSucccess]="!productStore.hasLoadingError()"
      (sucessBtnClick)="onViewMoreClick()"
      (errorBtnClick)="onLoadAgain()"
    ></amm-view-more>
  }
</div>
<div class="pt-12 pr-16 pl-16 pb-8"><amm-footer></amm-footer></div>
<p-toast
  position="bottom-center"
  key="product-operation-toast"
  [style]="{ 'max-width': '250px' }"
>
  <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
    <div class="flex flex-row gap-2 product-operation-toast-wrapper">
      <span class="material-symbols-outlined"> check </span>
      <span class="font-medium line-height-3 text-base">{{
        message.detail
      }}</span>
    </div>
  </ng-template>
</p-toast>
