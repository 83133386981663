import { provideHttpClient } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import {
  bootstrapApplication,
  provideProtractorTestingSupport,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppComponent } from './app/app.component';
import routeConfig from './app/app.routes';
import { environment } from './environments/environment';
if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    provideProtractorTestingSupport(),
    provideRouter(routeConfig, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(),
    provideOAuthClient(),
    MessageService,
    DynamicDialogConfig,
  ],
}).catch((err) => console.error(err));
