import { Routes } from '@angular/router';
import { HomeComponent } from './amm-pages/home/home.component';
import { forceLoginGuard } from './amm-share/guards';

const routeConfig: Routes = [
  {
    path: 'message:topmsgId',
    loadComponent: () =>
      import('../app/amm-pages/message-content/message-content.component').then(
        (m) => m.MessageContentComponent,
      ),
  },
  {
    path: 'message',
    loadComponent: () =>
      import('../app/amm-pages/page-message/page-message.component').then(
        (m) => m.PageMessageComponent,
      ),
    title: 'Message',
  },
  {
    path: 'shipping-policy/:storeId',
    loadComponent: () =>
      import('../app/amm-pages/shipping-policy/shipping-policy.component').then(
        (m) => m.ShippingPolicyComponent,
      ),
    title: 'Shipping policy',
  },
  {
    path: 'return-policy/:storeId',
    loadComponent: () =>
      import('../app/amm-pages/return-policy/return-policy.component').then(
        (m) => m.ReturnPolicyComponent,
      ),
    title: 'Return policy',
  },
  {
    path: 'legal-docs/:documentType',
    loadComponent: () =>
      import('../app/amm-pages/legal-doc/legal-doc.component').then(
        (m) => m.LegalDocComponent,
      ),
    title: 'Legal doc',
  },
  {
    path: 'help-center',
    loadComponent: () =>
      import('../app/amm-pages/help-center/help-center.component').then(
        (m) => m.HelpCenterComponent,
      ),
    title: 'Legal doc',
  },
  {
    path: 'register',
    loadComponent: () =>
      import('../app/amm-pages/page-register/page-register.component').then(
        (m) => m.PageRegisterComponent,
      ),
    title: 'Register',
  },
  {
    path: 'quick-product-view',
    loadComponent: () =>
      import(
        '../app/amm-pages/product-quick-view/product-quick-view.component'
      ).then((m) => m.ProductQuickViewComponent),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../app/amm-pages/main-page/main-page.component').then(
            (m) => m.MainPageComponent,
          ),
        outlet: 'mainBody',
      },
      {
        path: 'search-result',
        loadComponent: () =>
          import('../app/amm-pages/search-result/search-result.component').then(
            (m) => m.SearchResultComponent,
          ),
        outlet: 'mainBody',
      },
      {
        path: 'favorites',
        canActivate: [forceLoginGuard],
        loadComponent: () =>
          import('../app/amm-pages/favorites/favorites.component').then(
            (m) => m.FavoritesComponent,
          ),
        outlet: 'mainBody',
        title: 'Favorites',
      },
      {
        path: 'shopping-carts',
        loadComponent: () =>
          import(
            '../app/amm-pages/shopping-carts/shopping-carts.component'
          ).then((m) => m.ShoppingCartsComponent),
        outlet: 'mainBody',

        title: 'Shopping Carts',
      },
      {
        path: 'orders',
        canActivate: [forceLoginGuard],
        loadComponent: () =>
          import('../app/amm-pages/orders/orders.component').then(
            (m) => m.OrdersComponent,
          ),
        outlet: 'mainBody',

        title: 'Orders',
      },
      {
        path: 'orders/:poNumber',
        canActivate: [forceLoginGuard],
        loadComponent: () =>
          import('../app/amm-pages/order-details/order-details.component').then(
            (m) => m.OrderDetailsComponent,
          ),
        outlet: 'mainBody',
        title: 'Orders',
      },
      {
        path: 'details/:code',
        loadComponent: () =>
          import('../app/amm-pages/details/details.component').then(
            (m) => m.DetailsComponent,
          ),
        outlet: 'mainBody',
        title: 'Product details',
      },
      {
        path: 'store/:id',
        loadComponent: () =>
          import('../app/amm-pages/page-store/page-store.component').then(
            (m) => m.PageStoreComponent,
          ),
        outlet: 'mainBody',

        title: 'Store',
      },
    ],
    title: 'Home page',
  },
];

export default routeConfig;
