import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { AmmFooterComponent } from '../../amm-share/components/amm-footer/amm-footer.component';
import { AmmGlobalNavBarComponent } from '../../amm-share/components/amm-global-nav-bar/amm-global-nav-bar.component';
import { AmmSearchFilterComponent } from '../../amm-share/components/amm-search-filter/amm-search-filter.component';
import { GlobalNavWithIndicatorComponent } from '../../amm-share/components/global-nav-with-indicator/global-nav-with-indicator.component';
import { ItemCardComponent } from '../../amm-share/components/item-card/item-card.component';
import { ProductCardSkeletonLoaderComponent } from '../../amm-share/components/product-card-skeleton-loader/product-card-skeleton-loader.component';
import { ViewMoreComponent } from '../../amm-share/components/view-more/view-more.component';
import { ScrollDirectionDirective } from '../../amm-share/directives';
import { NavIndicatorService } from '../../amm-share/services';
import { ShoppingInfoStore } from '../../amm-share/stores';

@Component({
  selector: 'amm-home',
  standalone: true,
  imports: [
    CommonModule,
    ItemCardComponent,
    RouterLink,
    RouterOutlet,
    AmmFooterComponent,
    AmmGlobalNavBarComponent,
    ToastModule,
    SidebarModule,
    ProductCardSkeletonLoaderComponent,
    ViewMoreComponent,
    AmmSearchFilterComponent,
    GlobalNavWithIndicatorComponent,
    ScrollDirectionDirective,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  public navIndicatorService = inject(NavIndicatorService);
  public shoppingCartInfoStore = inject(ShoppingInfoStore);
  private router = inject(Router);
  /**
   *
   */
  constructor() {
    this.navIndicatorService.disableNavIndicator();
  }
  ngOnInit(): void {
    this.shoppingCartInfoStore.loadShoppingCarts();
    this.shoppingCartInfoStore.loadAllFavorites();
  }
}
