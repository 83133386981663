import { Routes } from '@angular/router';
import { DetailsComponent } from './amm-pages/details/details.component';
import { HomeComponent } from './amm-pages/home/home.component';

const routeConfig: Routes = [
  {
    path: 'details/:id',
    component: DetailsComponent,
    title: 'Home details',
  },
  {
    path: 'legal-docs/:documentType',
    loadComponent: () =>
      import('../app/amm-pages/legal-doc/legal-doc.component').then(
        (m) => m.LegalDocComponent,
      ),
    title: 'Legal doc',
  },
  {
    path: 'help-center',
    loadComponent: () =>
      import('../app/amm-pages/help-center/help-center.component').then(
        (m) => m.HelpCenterComponent,
      ),
    title: 'Legal doc',
  },
  {
    path: 'register',
    loadComponent: () =>
      import('../app/amm-pages/page-register/page-register.component').then(
        (m) => m.PageRegisterComponent,
      ),
    title: 'Register',
  },
  {
    path: 'quick-product-view',
    loadComponent: () =>
      import(
        '../app/amm-pages/product-quick-view/product-quick-view.component'
      ).then((m) => m.ProductQuickViewComponent),
  },
  {
    path: 'search-products',
    loadComponent: () =>
      import(
        '../app/amm-pages/page-search-products/page-search-products.component'
      ).then((m) => m.PageSearchProductsComponent),
    title: 'Register',
  },
  {
    path: 'favorites',
    loadComponent: () =>
      import('../app/amm-pages/favorites/favorites.component').then(
        (m) => m.FavoritesComponent,
      ),
    title: 'Favorites',
  },
  {
    path: 'shopping-carts',
    loadComponent: () =>
      import('../app/amm-pages/shopping-carts/shopping-carts.component').then(
        (m) => m.ShoppingCartsComponent,
      ),
    title: 'Register',
  },
  {
    path: 'orders',
    loadComponent: () =>
      import('../app/amm-pages/order-details/order-details.component').then(
        (m) => m.OrderDetailsComponent,
      ),
    title: 'Search',
  },
  {
    path: '',
    component: HomeComponent,
    title: 'Home page',
  },
];

export default routeConfig;
