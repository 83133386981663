import {
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CommonStore, ShoppingInfoStore } from './amm-share/stores';

@Component({
  selector: 'amm-root',
  standalone: true,
  imports: [RouterLink, RouterOutlet],

  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Amm-Buyer';

  private shoppingCartStore = inject(ShoppingInfoStore);
  private commonStore = inject(CommonStore);

  constructor() {}

  ngOnInit(): void {
    this.commonStore.loadAllCategory();
  }

  ngOnDestroy(): void {
    this.shoppingCartStore.saveShoppingCartsToDB();
  }
  @HostListener('window:storage')
  onLocalStorageChange() {}
}
