<div class="flex justify-between global-nav-wrapper">
  <div class="flex items-center gap-6 logo-container">
    <a [routerLink]="['/']">
      <img class="brand-logo" src="../../../../assets/img/logo/logo_complete_horizontal.png" alt="logo"
        aria-hidden="true" />
    </a>
    <button class="primary-button" type="button" (click)="onExplore()">
      Explore
    </button>
  </div>

  <div class="flex justify-center items-start search-bar-wrapper gap-2">
    <div class="w-full">
      <div class="flex search-bar-section gap-3 w-full h-full">
        <span class="items-center pi pi-search text-2xl search-icon"></span>
        <input class="search-bar h-full" type="text" placeholder="Search Product" [(ngModel)]="searchValue"
          (ngModelChange)="setAutoCompleteValue()" (keydown.enter)="setSearchValue()" (blur)="noSearch()"
          (focus)="showSearchHistoryItems()" />
        @if(searchValue){
        <span class="items-center pi pi-times delete-icon mr-4" (click)="deleteSearchValue()"></span>
        }
      </div>
      @if(showSearchHistory){
      <div class="w-full search-content">
        <div class="search-history-title w-full">Search history</div>
        @for (item of searchHistory; track item;let i = $index) {
        <div class="flex flex-row items-center justify-between w-full">
          <div class="flex flex-row gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 21C9.9 21 8.04167 20.3625 6.425 19.0875C4.80833 17.8125 3.75833 16.1833 3.275 14.2C3.20833 13.95 3.25833 13.7208 3.425 13.5125C3.59167 13.3042 3.81667 13.1833 4.1 13.15C4.36667 13.1167 4.60833 13.1667 4.825 13.3C5.04167 13.4333 5.19167 13.6333 5.275 13.9C5.675 15.4 6.5 16.625 7.75 17.575C9 18.525 10.4167 19 12 19C13.95 19 15.6042 18.3208 16.9625 16.9625C18.3208 15.6042 19 13.95 19 12C19 10.05 18.3208 8.39583 16.9625 7.0375C15.6042 5.67917 13.95 5 12 5C10.85 5 9.775 5.26667 8.775 5.8C7.775 6.33333 6.93333 7.06667 6.25 8H8C8.28333 8 8.52083 8.09583 8.7125 8.2875C8.90417 8.47917 9 8.71667 9 9C9 9.28333 8.90417 9.52083 8.7125 9.7125C8.52083 9.90417 8.28333 10 8 10H4C3.71667 10 3.47917 9.90417 3.2875 9.7125C3.09583 9.52083 3 9.28333 3 9V5C3 4.71667 3.09583 4.47917 3.2875 4.2875C3.47917 4.09583 3.71667 4 4 4C4.28333 4 4.52083 4.09583 4.7125 4.2875C4.90417 4.47917 5 4.71667 5 5V6.35C5.85 5.28333 6.8875 4.45833 8.1125 3.875C9.3375 3.29167 10.6333 3 12 3C13.25 3 14.4208 3.2375 15.5125 3.7125C16.6042 4.1875 17.5542 4.82917 18.3625 5.6375C19.1708 6.44583 19.8125 7.39583 20.2875 8.4875C20.7625 9.57917 21 10.75 21 12C21 13.25 20.7625 14.4208 20.2875 15.5125C19.8125 16.6042 19.1708 17.5542 18.3625 18.3625C17.5542 19.1708 16.6042 19.8125 15.5125 20.2875C14.4208 20.7625 13.25 21 12 21ZM13 11.6L15.5 14.1C15.6833 14.2833 15.775 14.5167 15.775 14.8C15.775 15.0833 15.6833 15.3167 15.5 15.5C15.3167 15.6833 15.0833 15.775 14.8 15.775C14.5167 15.775 14.2833 15.6833 14.1 15.5L11.3 12.7C11.2 12.6 11.125 12.4875 11.075 12.3625C11.025 12.2375 11 12.1083 11 11.975V8C11 7.71667 11.0958 7.47917 11.2875 7.2875C11.4792 7.09583 11.7167 7 12 7C12.2833 7 12.5208 7.09583 12.7125 7.2875C12.9042 7.47917 13 7.71667 13 8V11.6Z"
                fill="#9E9E9E" />
            </svg>
            <div (click)="setSearchValueFromHistory(item)">{{item}}</div>
          </div>
          <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="secondary"
            (onClick)="delOneSearchHistory(i)" />
        </div>
        }
      </div>
      }
      @if(showAutoComplete){
      <div class="w-full search-content gap-4">
        @for (item of autoComplete; track item;let i = $index) {
        <div class="flex flex-row items-center justify-between w-full">
          <div (click)="setSearchValueFromHistory(item)">{{item}}</div>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path
              d="M3.75 16.25C3.3375 16.25 2.98438 16.1031 2.69063 15.8094C2.39688 15.5156 2.25 15.1625 2.25 14.75V4.25C2.25 3.8375 2.39688 3.48438 2.69063 3.19063C2.98438 2.89688 3.3375 2.75 3.75 2.75H8.25C8.4625 2.75 8.64063 2.82187 8.78438 2.96562C8.92813 3.10938 9 3.2875 9 3.5C9 3.7125 8.92813 3.89062 8.78438 4.03437C8.64063 4.17813 8.4625 4.25 8.25 4.25H3.75V14.75H14.25V10.25C14.25 10.0375 14.3219 9.85938 14.4656 9.71563C14.6094 9.57188 14.7875 9.5 15 9.5C15.2125 9.5 15.3906 9.57188 15.5344 9.71563C15.6781 9.85938 15.75 10.0375 15.75 10.25V14.75C15.75 15.1625 15.6031 15.5156 15.3094 15.8094C15.0156 16.1031 14.6625 16.25 14.25 16.25H3.75ZM14.25 5.3L7.8 11.75C7.6625 11.8875 7.4875 11.9563 7.275 11.9563C7.0625 11.9563 6.8875 11.8875 6.75 11.75C6.6125 11.6125 6.54375 11.4375 6.54375 11.225C6.54375 11.0125 6.6125 10.8375 6.75 10.7L13.2 4.25H11.25C11.0375 4.25 10.8594 4.17813 10.7156 4.03437C10.5719 3.89062 10.5 3.7125 10.5 3.5C10.5 3.2875 10.5719 3.10938 10.7156 2.96562C10.8594 2.82187 11.0375 2.75 11.25 2.75H15C15.2125 2.75 15.3906 2.82187 15.5344 2.96562C15.6781 3.10938 15.75 3.2875 15.75 3.5V7.25C15.75 7.4625 15.6781 7.64062 15.5344 7.78438C15.3906 7.92812 15.2125 8 15 8C14.7875 8 14.6094 7.92812 14.4656 7.78438C14.3219 7.64062 14.25 7.4625 14.25 7.25V5.3Z"
              fill="#9E9E9E" />
          </svg>
        </div>
        }
      </div>
      }
    </div>
    <!-- <p-overlayPanel #op class="flex px-2">
 
    </p-overlayPanel> -->
    @if(hasFilterButton()){
    <p-button [label]="getFilterButtonLable()" [outlined]="true" [rounded]="true" [severity]="'secondary'"
      (onClick)="showHideFilter()" [style]="{width:'170px'}"></p-button>
    }
  </div>
  <div class="flex items-center gap-8 p-2 function-container">
    <div class="flex">
      <amm-button-with-bage [iconName]="iconName.favorite" iconDesciption="favorites"
        (onButtonClick)="onButtonClick($event)"></amm-button-with-bage>
      <amm-button-with-bage [iconName]="iconName.orders" iconDesciption="orders"
        (onButtonClick)="onButtonClick($event)"></amm-button-with-bage>
      <amm-button-with-bage [iconName]="iconName.shoppingCart" iconDesciption="carts"
        [counter]="shopInfoStore.shoppingCartCount()" (onButtonClick)="onButtonClick($event)"></amm-button-with-bage>
    </div>

    @if (storageService.hasLocalItem("buyerInfo")) {
    <!-- TODO: This need to be finalized with UI design team -->
    <div class="flex flex-row items-center gap-1 profile-img-warpper cursor-pointer" (click)="op.toggle($event)">
      <img #panelHolder class="avatar-img" src="../../../../assets/img/avatar.png" alt="" />
      <span class="material-symbols-outlined">keyboard_arrow_down</span>
      <p-overlayPanel #op>
        <div class="flex flex-col justify-start p-2 gap-2 text-2xl min-h-96 min-w-96">
          Hello, Trueman this will be implemented after login
        </div>
      </p-overlayPanel>
    </div>
    } @else {
    <button class="button-action-primary" type="button" (click)="onLogin()">
      Log in
    </button>
    }
  </div>
</div>